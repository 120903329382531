<template>
	<div class=''>
		<!-- 导航栏 -->
		<div class="h60 bg-f lh60 flex pr303 row-right nav_box">
			<div class="flex col-center pointer" :class="{'active':item.name==aboutRouterName}"
				v-for="(item,i) in navList" :key="i" @click="btnNav(item)">
				<span class="fs18 fw-500">{{item.title}}</span>
				<div class="line mr40 ml40" v-if="i!=navList.length-1"></div>
			</div>
		</div>
		<!-- //导航栏 -->

		<router-view></router-view>
	</div>
</template>

<script>
	import { mapState } from 'vuex'

	export default {
		name: 'aboutUs',
		computed: {
			...mapState(['aboutRouterName'])
		},

		data() {
			return {
				navList: [{
						title: '关于铭智生科',
						url: '/aboutUs',
						name: 'aboutMz'
					}, {
						title: '团队介绍',
						url: '/teamInfo',
						name: 'teamInfo'
					},
					// {
					//   title: '专家顾问',
					//   url: '/expertConsultant',
					//   name: 'expertConsultant'
					// },
					{
						title: '合作案例',
						url: '/cooperationCase',
						name: 'cooperationCase'
					}, {
						title: '联系我们',
						url: '/contactUs',
						name: 'contactUs'
					},
				]
			};
		},

		components: {},

		created() {
			this.$store.commit('editRouterName', this.$route.name)
		},

		mounted() {},

		methods: {
			btnNav(item) {
				this.$router.push(item.url)
			}
		}
	}
</script>
<style lang='scss' scoped>
	.line {
		width: 1px;
		height: 40px;
		opacity: 0.07;
		border: 0.5px solid #000000;
	}

	.active {
		color: #f7b500 !important;
	}

	.nav_box {
		position: sticky;
		top: 90px;
		background: #ffffff;
		/* background-color: transparent; */
		border: 1px solid rgba(151, 151, 151, 0.2);
		z-index: 990;
	}
</style>
